<template>
  <section>
    <b-row>
      <b-col v-if="user_type === 'counselor'">
        <b-card class="card-app-design assigned-staff-card">
          <h4>Student Registration Link</h4>
          <b-button
            class="btn btn-sm btn-outline-primary mr-1"
            @click="openStudentLink()"
          >
            <span class="mr-25 align-middle">Click Here</span>
          </b-button>
        </b-card>
      </b-col>

      <!-- Lead wise filter -->
      <b-col cols="12" v-if="user_type === 'counselor'">
        <b-card title="Filters">
          <b-row>
            <b-col>
              <b-form-group label="Lead Type">
                <v-select
                  placeholder="Lead Type"
                  v-model="leadType"
                  :options="leadTypeOptions"
                  :clearable="true"
                  item-text="label"
                  :reduce="(item) => item.value"
                  @input="
                    () => {
                      handleLeadSourceOptions();
                      getHomeData();
                    }
                  "
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Lead Source">
                <!-- {{ leadSource }} -->
                <v-select
                  placeholder="Lead Source"
                  v-model="leadSource"
                  :disabled="leadType ? false : true"
                  :options="leadSourceOptions"
                  :clearable="true"
                  item-text="label"
                  :reduce="(item) => item.value"
                  @input="onSelectLeadSource"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        v-if="
          leadType == 'franchise' && leadSource && user_type !== 'franchise'
        "
      >
        <b-card
          :title="(user_type !== 'franchise' ? 'Franchise ' : '') + 'Filters'"
        >
          <!-- Toggle -->
          <div v-if="showAllLevelToggle()" class="mb-2">
            <div class="d-flex align-items-center mb-1">
              <p class="mr-1">
                Show All Level Data
                <small>{{ getStudentSupportDescription() }}</small>
              </p>
              <b-form-checkbox
                v-model="showAllLevelData"
                switch
                inline
                @input="onShowAllLevelData"
              />
            </div>
            <hr />
          </div>
          <b-row>
            <!-- Area Franchise -->
            <b-col
              cols="4"
              v-if="user_details.franchise_level === 'master' || master_f_id"
            >
              <b-form-group>
                <p style="margin-bottom: 5px">
                  Area Franchise
                  <small style="color: #aeaeae"
                    >(Select to get Micro Franchise)</small
                  >
                </p>
                <v-select
                  placeholder="Area Franchise"
                  v-model="area_f_id"
                  :options="area_franchises"
                  :clearable="true"
                  label="user_name"
                  :reduce="(item) => item.user_id"
                  @input="onSelectAreaFranchise"
                />
              </b-form-group>
            </b-col>

            <!-- Micro Franchise -->
            <b-col
              cols="4"
              v-if="area_f_id || user_details.franchise_level === 'area'"
            >
              <b-form-group label="Micro Franchise">
                <v-select
                  placeholder="Micro Franchise"
                  v-model="micro_f_id"
                  :options="micro_franchises"
                  :clearable="true"
                  label="user_name"
                  :reduce="(item) => item.user_id"
                  @input="onSelectMicroFranchise"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <!-- Student Support -->
            <b-col
              cols="4"
              v-if="
                (leadType === 'franchise' && leadSource) ||
                user_type === 'franchise'
              "
            >
              <b-form-group>
                <p style="margin-bottom: 5px">
                  Student Support
                  <small style="color: #aeaeae">{{
                    getStudentSupportDescription()
                  }}</small>
                </p>
                <v-select
                  placeholder="Student Support"
                  v-model="ss_id"
                  :options="ss_options"
                  :clearable="true"
                  label="user_name"
                  :reduce="(item) => item.user_id"
                  @input="onSelectStudentSupport"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col cols="12" v-if="ss_id || area_f_id || micro_f_id || master_f_id">
        <agent-students
          :show_all_level_data="showAllLevelData"
          :lead="getLeadData"
          :agent_user_id="
            ss_id
              ? ss_id
              : micro_f_id
              ? micro_f_id
              : area_f_id
              ? area_f_id
              : master_f_id
          "
        />
      </b-col>

      <!-- Franchise responses -->
      <b-col cols="12" v-if="user_type === 'counselor' && quiz_responses">
        <quiz-responses :quiz_responses="quiz_responses" />
      </b-col>

      <!-- Pyramid Chart -->
      <b-col cols="12">
        <pyramid-chart :data="pyramidChartData"></pyramid-chart>
      </b-col>

      <!-- Student Counts -->
      <b-col cols="12">
        <counts :data="studentCounts" header="Students" />
      </b-col>

      <!-- Students Assigned Line Graph -->
      <b-col cols="12">
        <e-chart
          :series="echartData.series"
          :xAxisData="echartData.xAxisData"
          @echartDateRange="getEchartDateRange"
        />
      </b-col>
    </b-row>

    <!-- Student Application Status Graph and list -->
    <b-card>
      <b-row>
        <b-col cols="5">
          <donut-chart
            :series="applicationsStatus"
            :labels="applicationsStatusLabels"
          />
        </b-col>
        <b-col cols="7">
          <applications-statuses
            @statusSelected="applicationStatusSelected"
            @applicationStatusDate="getApplicationStatusDate"
            :status_id="selectedStatusId"
            :data="applicationStatusCount"
            :status="selectedStatus"
          />
        </b-col>
        <b-col cols="12">
          <applications-wise-students
            :lead="getLeadData"
            :agent_user_id="micro_f_id ? micro_f_id : area_f_id"
            :status_id="selectedStatusId"
            :status="selectedStatus"
            :applicationStatusDate="applicationStatusDate"
          />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BMediaAside,
  BMedia,
  BAvatar,
  BFormSelect,
  BButton,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import ApplicationsStatuses from "@/views/components/StaffHomeComponents/ApplicationsStatuses.vue";
import DonutChart from "@/views/components/StaffHomeComponents/DonutChart.vue";
import EChart from "@/views/components/StaffHomeComponents/EChart.vue";
import ApplicationsWiseStudents from "@/views/components/StaffHomeComponents/ApplicationsWiseStudents.vue";
import CommonServices from "@/apiServices/CommonServices";
import Counts from "@/views/components/StaffHomeComponents/Counts.vue";
import PyramidChart from "./PyramidChart.vue";
import CounselorServices from "@/apiServices/CounselorServices";
import store from "@/store";
import { VueSelect } from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { FILESURL } from "@/config";
import vSelect from "vue-select";
import QuizResponses from "./QuizResponses.vue";
import AgentServices from "@/apiServices/AgentServices";
import AgentStudents from "@/views/components/StaffHomeComponents/AgentStudents.vue";

export default {
  components: {
    AgentStudents,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BCard,
    ApplicationsStatuses,
    DonutChart,
    EChart,
    ApplicationsWiseStudents,
    BMedia,
    BMediaAside,
    Counts,
    PyramidChart,
    VueSelect,
    BFormSelect,
    vSelect,
    BButton,
    QuizResponses,
    BFormGroup,
    BFormCheckbox,
  },
  data() {
    return {
      leadType: null,
      leadSource: null,
      leadTypeOptions: [
        { label: "Direct", value: "direct" },
        { label: "General Associate", value: "agent" },
        { label: "Exclusive Associate", value: "agent" },
        // { label: "Franchise", value: "franchise" },
      ],
      leadSourceOptions: [],

      quiz_responses: [],

      FILESURL,
      applicationStatusCount: [],
      applicationsStatus: [],
      applicationsStatusLabels: [],
      applicationWiseStudents: [],
      selectedStatusId: null,
      selectedStatus: {},
      studentCounts: [],
      applicationStatusDate: {
        intake_month: null,
        intake_year: null,
      },
      echartDateRange: {
        startDate: "",
        endDate: "",
      },
      echartData: {
        xAxisData: [],
        series: [],
      },

      assignedAgents: [],
      selectedAgentUserId: "",
      pyramidChartData: [],
      baseURL: window.location.origin,
      area_franchises: [],
      micro_franchises: [],
      ss_options: [],
      master_f_id: null,
      area_f_id: null,
      micro_f_id: null,
      ss_id: null,

      showAllLevelData: false,
      currentFranchiseLevel: null,
    };
  },

  computed: {
    user_type() {
      const val = store.getters["user/getUserDetails"].user_type;
      if (val === "counselor") {
      } else if (val === "franchise") {
        const fl = store.getters["user/getUserDetails"].franchise_level;

        // this.leadType = "franchise";
        this.franchise_level = fl;
        this.currentFranchiseLevel = fl;

        if (fl === "master") {
          this.getAreaFranchises();
        } else if (fl === "area") {
          this.getMicroFranchises(store.getters["user/getUserDetails"].user_id);
        }

        // this.getStudentSupportList();
      }
      return val;
    },
    user_details() {
      return store.getters["user/getUserDetails"];
    },
  },

  methods: {
    onChange() {
      this.$nextTick(() => {
        this.getHomeData();
      });
    },

    getTopLevelFranchise() {
      if (this.area_f_id) {
        return this.area_f_id;
      }

      if (this.user_type === "franchise") {
        return this.user_details.user_id;
      } else if (
        this.user_type === "counselor" &&
        this.leadType === "franchise" &&
        this.leadSource
      ) {
        return this.leadSource;
      }

      return null;
    },

    openStudentLink() {
      const link = `${this.baseURL}/register?refer_user_id=${store.getters["user/getUserDetails"].user_id}`;
      window.open(link, "_blank");
    },

    showAllLevelToggle() {
      if (
        this.micro_f_id ||
        (this.user_type === "franchise" &&
          this.user_details.franchise_level === "micro")
      )
        return false;
      return true;
    },

    onShowAllLevelData(val) {
      this.showAllLevelData = val;
      this.getHomeData();
    },

    resetValues() {
      this.applicationStatusCount = [];
      this.pplicationsStatus = [];
      this.applicationsStatusLabels = [];
      this.applicationWiseStudents = [];
      this.selectedStatusId = null;
      this.selectedStatus = {};
      this.studentCounts = [];
      this.applicationStatusDate = {
        intake_month: null,
        intake_year: null,
      };
      this.echartDateRange = {
        startDate: "",
        endDate: "",
      };
      this.echartData = {
        xAxisData: [],
        series: [],
      };

      this.assignedAgents = [];
      this.selectedAgentUserId = "";
      this.pyramidChartData = [];
    },

    async handleLeadSourceOptions() {
      this.leadSource = null;

      this.area_f_id = null;
      this.micro_f_id = null;
      this.master_f_id = null;
      this.ss_options = [];
      this.area_franchises = [];
      this.micro_franchises = [];

      if (!this.leadType) {
        this.leadSource = null;
      }
      if (this.leadType === "direct") {
        this.leadSourceOptions = [
          {
            label: "Facebook",
            value: "facebook",
          },
          {
            label: "Instagram",
            value: "instagram",
          },
          {
            label: "Switz Website",
            value: "SwitzWebsite",
          },
          {
            label: "Alumni",
            value: "alumni",
          },
        ];
      }
      if (this.leadType === "agent") {
        const res = await CounselorServices.getAssignedAgents({
          leadUserType: this.leadType,
        });
        let assignedAgents = res.data.data;
        this.leadSourceOptions = [];
        for (let assignedAgent of assignedAgents) {
          this.leadSourceOptions.push({
            label:
              assignedAgent.user_firstname + " " + assignedAgent.user_lastname,
            value: assignedAgent.user_id,
          });
        }
      }
      if (this.leadType === "franchise") {
        const res = await CounselorServices.getAssignedAgents({
          leadUserType: this.leadType,
        });
        let assignedAgents = res.data.data;
        this.leadSourceOptions = [];
        for (let assignedAgent of assignedAgents) {
          this.leadSourceOptions.push({
            label:
              assignedAgent.user_firstname + " " + assignedAgent.user_lastname,
            value: assignedAgent.user_id,
            franchise_level: assignedAgent.franchise_level,
          });
        }
      }
    },

    onSelectLeadSource(val) {
      this.master_f_id = null;
      this.area_f_id = null;
      this.micro_f_id = null;
      this.ss_id = null;
      this.ss_options = [];
      this.area_franchises = [];
      this.micro_franchises = [];

      if (!val) {
        this.getHomeData();
        return;
      }

      this.$nextTick(() => {
        if (this.leadType == "franchise") {
          this.getStudentSupportList();

          const f = this.leadSourceOptions.find((i) => i.value === val);
          if (f.franchise_level) {
            this.currentFranchiseLevel = f.franchise_level;
            if (f.franchise_level === "master") {
              this.master_f_id = val;
              this.getAreaFranchises();
            } else if (f.franchise_level === "area") {
              this.area_f_id = val;
              this.getMicroFranchises(val);
            } else if (f.franchise_level === "micro") {
              this.micro_f_id = val;
            }
          }
        }

        this.getHomeData();
      });
    },

    async getAreaFranchises() {
      try {
        let res;
        if (this.user_type === "franchise") {
          res = await AgentServices.getAreaFranchises();
        } else if (this.user_type === "counselor") {
          res = await CounselorServices.getAreaFranchise({
            master_f_id: this.master_f_id,
          });
        }

        if (res && res.data.status) {
          this.area_franchises = res.data.data;
        } else {
          console.error(`false in getAreaFranchises `);
        }
      } catch (error) {
        console.error(`Error in getAreaFranchises `, error);
      }
    },

    async getMicroFranchises(area_f_id) {
      try {
        let res;
        if (this.user_type === "franchise") {
          res = await AgentServices.getMicroFranchises({ area_f_id });
        } else if (this.user_type === "counselor") {
          res = await CounselorServices.getMicroFranchise({ area_f_id });
        }

        if (res && res.data.status) {
          this.micro_franchises = res.data.data;
        }
      } catch (error) {
        console.error(`Error in getMicroFranchises `, error);
      }
    },

    async getStudentSupportList() {
      try {
        let res;
        if (this.user_type === "franchise") {
          res = await AgentServices.getAllStudentSupportList({
            area_f_id: this.area_f_id,
            micro_f_id: this.micro_f_id,
          });
        }

        if (
          this.user_type === "counselor" &&
          this.leadType === "franchise" &&
          (this.leadSource || this.area_f_id || this.micro_f_id)
        ) {
          res = await CounselorServices.getAllStudentSupportList({
            franchise_user_id: this.leadSource,
            area_f_id: this.area_f_id,
            micro_f_id: this.micro_f_id,
          });
        }

        if (res && res.data.status) {
          this.ss_options = res.data.data;
        }
      } catch (error) {
        console.error(`Error in getStudentSupportList `, error);
      }
    },

    async onSelectAreaFranchise(area_f_id) {
      this.getMicroFranchises(area_f_id);
      this.currentFranchiseLevel = "area";
      if (this.area_f_id === null) {
        this.currentFranchiseLevel = "master";
      }
      this.micro_f_id = null;

      this.$nextTick(() => {
        this.getStudentSupportList();
        this.getHomeData();
      });
    },

    async onSelectMicroFranchise() {
      this.ss_id = null;
      this.showAllLevelData = false;
      this.currentFranchiseLevel = "micro";
      if (this.micro_f_id === null) {
        this.currentFranchiseLevel = "area";
      }

      this.$nextTick(() => {
        this.getStudentSupportList();
        this.getHomeData();
      });
    },

    onSelectStudentSupport() {
      this.$nextTick(() => this.getHomeData());
    },

    applicationStatusSelected(item) {
      this.selectedStatusId = item.status_id;
      this.selectedStatus = item;
    },

    getApplicationStatusDate(date) {
      this.applicationStatusDate = date;
      this.getHomeData();
    },

    getEchartDateRange({ startDate, endDate }) {
      this.echartDateRange.startDate = startDate;
      this.echartDateRange.endDate = endDate;
      this.getHomeData();
    },

    getLeadData() {
      // if (this.user_type === "franchise") return null;
      return {
        leadType: this.leadType,
        leadSource: this.leadType == "direct" ? this.leadSource : null,
        agent_user_id:
          this.leadType !== "direct"
            ? this.ss_id
              ? this.ss_id
              : this.micro_f_id
              ? this.micro_f_id
              : this.area_f_id
              ? this.area_f_id
              : this.leadSource
            : null,
        franchise_level: this.currentFranchiseLevel,
      };
    },

    getStudentSupportDescription() {
      let franchiseName = "";

      if (this.micro_f_id && this.micro_franchises.length) {
        franchiseName =
          this.micro_franchises.find((item) => item.user_id === this.micro_f_id)
            ?.user_name || "";
      } else if (this.area_f_id && this.area_franchises.length) {
        franchiseName =
          this.area_franchises.find((item) => item.user_id === this.area_f_id)
            ?.user_name || "";
      } else {
        if (this.user_type === "franchise") {
          franchiseName =
            this.user_details.user_firstname +
            " " +
            this.user_details.user_lastname;
        } else if (this.user_type === "counselor") {
          franchiseName = this.leadSourceOptions.find(
            (leadSource) => leadSource.value === this.leadSource
          ).label;
        }
      }

      if (franchiseName) {
        return `(For ${franchiseName})`;
      }
      return "";
    },

    async getHomeData() {
      try {
        const res = await CommonServices.getHomeData({
          application: {
            ...this.applicationStatusDate,
          },
          // agent_user_id: this.selectedAgentUserId,
          echart: {
            ...this.echartDateRange,
          },
          lead: this.getLeadData(),
          ss_id: this.ss_id,

          showAllLevelData: this.showAllLevelData
            ? this.showAllLevelData
            : null,
        });
        if (res.data.status) {
          this.studentCounts = res.data.data.studentCounts;

          this.echartData.xAxisData =
            res.data.data.formattedMonthlyStudentsJoined.xAxisData;
          this.echartData.series =
            res.data.data.formattedMonthlyStudentsJoined.seriesData;

          const applicationStatusCount = res.data.data.applicationStatusCount;

          this.applicationStatusCount = applicationStatusCount;

          this.selectedStatusId = applicationStatusCount[0].status_id;
          this.selectedStatus = applicationStatusCount[0];

          this.pyramidChartData = res.data.data.pyramidChartData;

          if (applicationStatusCount.length > 1) {
            this.applicationsStatus = applicationStatusCount.map(
              (i) => i.count
            );

            this.applicationsStatusLabels = applicationStatusCount.map(
              (i) => i.status_name
            );
          }

          this.quiz_responses = [];
          if (
            this.leadType !== "direct" &&
            this.leadSource &&
            this.user_type === "counselor"
          ) {
            const res = await CounselorServices.getFranchiseQuizResponse({
              agent_user_id: this.leadSource,
            });

            if (res.data.status) {
              this.quiz_responses = res.data.data;
            }
          }
        } else {
          this.resetValues();
        }
      } catch (error) {
        console.error(`Error in getHomeData `, error);
      }
    },
  },

  watch: {
    user_type(newVal, oldVal) {
      if (newVal !== undefined) {
        if (newVal === "franchise") {
          this.getStudentSupportList();
          this.getHomeData();
        }
      }
    },

    user_details(newVal, oldVal) {
      if (newVal !== undefined) {
        if (newVal.user_type === "franchise") {
          if (newVal.franchise_level) {
            if (newVal.franchise_level === "master") {
              this.master_f_id = newVal.user_id;
            } else if (newVal.franchise_level === "area") {
              this.area_f_id = newVal.user_id;
            } else if (newVal.franchise_level === "micro") {
              this.micro_f_id = newVal.user_id;
            }
          }
        }
      }
    },
  },

  beforeMount() {
    this.getHomeData();
  },
};
</script>

<style></style>
